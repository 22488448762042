import router from "../Router";
import {AComponent} from "../abstract/AComponent";

export class LangSwitch extends AComponent {
	initialize(params) {

        this.events = {
            "change select" : 'onSelect'
        };

		super.initialize(params);

		this.isShown = false;
	}

	onSelect(e) {
		e.preventDefault();
		window.location.href = e.target.value;
	}

}