import {AScrollableElmt} from "../abstract/AScrollableElmt";
import {linearEquation} from "../utils/linearEquation";
import {setTransform} from "../utils/setTransform";
//import {ajax} from "../utils/ajax";

export class Parallax extends AScrollableElmt {
	initialize (params) {
        this.start_position = this.start_position || 0;
        this.end_position = this.end_position || 0;

        this._start_anim = 0;
        this._end_anim = 0;

        this.launch_anim = 1;
        this.end_anim = 0;

        this.direction = 'Y';
        this.viewport = 'screen';


        super.initialize(params);


        if(F.isMobile()) {
            this.start_position *= 0.5;
            this.end_position *= 0.5;
        }
	}
    resize(wW, wH) {
	    setTransform(this.$el, '');
        super.resize(wW, wH);

        let start = this.top - wH;

        this._start_anim = start + (wH - (wH * this.launch_anim));

        if(this.viewport === 'screen') {
            this._end_anim = start + (wH - (wH * this.end_anim)) + this.height;
        } else {
            this._end_anim = document.body.offsetHeight;
        }



        let start_position  = this.start_position;
        if(this.top < this.wH) { //si c'est dans le viewport par defaut
            start_position = 0;
            this._start_anim = 0;
        }


        this.equation = linearEquation(this._start_anim, this._end_anim, start_position, this.end_position);

	}

	scroll(scrollTop, lastScrollTop) {
        super.scroll(scrollTop, lastScrollTop);
        if(this.viewport === 'full') {
            setTransform(this.$el, 'translate'+this.direction+'('+this.equation(scrollTop)+'px)');
        }
    }
    scrollInViewport(scrollTop, lastScrollTop) {
        if(this.viewport === 'screen') {
            setTransform(this.$el, 'translate' + this.direction + '(' + this.equation(scrollTop) + 'px)');
        }

    }
}