import router from "../Router";
import {AComponent} from "../abstract/AComponent";

export class GrilleClients extends AComponent {
	initialize(params) {

        super.initialize(params);
        console.log("initialize GrilleClients");

        this.$$clients = '.client';
        this.$pagination = ".pagination";
        this.clientPerPage = 21;
        this.numPages = 0;
        this.$$bullets = null;
        this.$$bulletsWrapper = null;
        this.currentIndex = -1;
        this.$prev = null;
        this.$next = null;

        this.$refs = {
            /** @var SelectFilterItem */
            items : null,
        };

        this.events = {
            "click .a-slider-bullets a": "onClickBullet"
        }
	}

    bind() {
        super.bind();
        // this.onChangeType = this.onChangeType.bind(this);
        this.onChangeType = this.onChangeType.bind(this);

        setTimeout(function () {
            this.$refs.items.on('apply', this.onChangeType);
            this.$$clients.forEach($client => {
                $client.classList.add('show');
            });
            this.majPagination();
        }.bind(this), 100);
    }

    onChangeType(e){
		let values = this.$refs.items.values;
        this.$$clients.forEach($client => {
            $client.classList.remove('show');
        });
		if(values[0] === null) {
			this.$$clients.forEach($client => {
				$client.classList.add('show');
			});
		} else {
			values.forEach(value => {
                let els = this.$el.getElementsByClassName(value);
                Array.from(els).forEach((el) => {
                    el.classList.add('show');
                });
			})
		}
        this.majPagination();
	}

    onClickBullet (e) {
        e.preventDefault();
        e.stopPropagation();
        this.goToSlide(parseInt(e.currentTarget.getAttribute("data-index")))
    }

    majPagination () {
        let $clients = this.$el.querySelectorAll(".client.show").length;
        this.numPages = Math.ceil($clients / this.clientPerPage);
        this.$pagination.innerHTML = "";

        console.log('update pagination');

        if (this.numPages > 1) {
            if(!this.$$bulletsWrapper) {
                this.$$bulletsWrapper = document.createElement("div");
                this.$$bulletsWrapper.className = "a-slider-bullets";
            }

            let bulletsElems = "", bulletsNum = 0;
            bulletsElems += '<a class="prev" data-index="-1" href="#">' + F.Svgs.arrow_slide + "</a>";

            for (let i = 0; i < this.numPages; i++) {
                bulletsElems += '<a class="bullet" data-index="' + bulletsNum + '" href="#" ' + (bulletsNum === this.currentIndex ? 'class="current"' : "") + ">.</a>"
                bulletsNum++;
            }

            bulletsElems += '<a class="next" data-index="1" href="#">' + F.Svgs.arrow_slide + "</a>";
            this.$$bulletsWrapper.innerHTML = bulletsElems;
            this.$pagination.appendChild(this.$$bulletsWrapper);
            this.$$bullets = this.$$bulletsWrapper.querySelectorAll(".bullet");
            this.$next = this.$$bulletsWrapper.querySelector(".next");
            this.$prev = this.$$bulletsWrapper.querySelector(".prev");

        }

        0 === this.currentIndex && (this.currentIndex = -1);
        this.goToSlide(0);
    }

    goToSlide (slideIndex) {
        let $el = this;

        if (slideIndex === this.currentIndex) return false;
        this.$$bullets && this.$$bullets[this.currentIndex] && this.$$bullets[this.currentIndex].classList.remove("current");

        this.$$clients.forEach(function (t) {
            t.classList.remove("currentpage")
        });

        let n = 0;
        this.$el.querySelectorAll(".client.show").forEach(function (i) {
            Math.max(0, Math.floor(n / $el.clientPerPage)) === slideIndex && i.classList.add("currentpage");
            n++;
        })

        this.$$bullets && this.$$bullets[slideIndex] && this.$$bullets[slideIndex].classList.add("current");
        if(this.$prev && this.$next) {
            this.$prev.setAttribute("data-index", slideIndex - 1);
            (slideIndex + 1 > this.numPages - 1) ? this.$next.setAttribute("data-index", -1) : this.$next.setAttribute("data-index", slideIndex + 1)
        }

        this.currentScroll = F._.scrollTop - 130 + this.$refs.items.$el.getBoundingClientRect().top;
        -1 !== this.currentIndex && window.scrollTo(0, this.currentScroll);
        this.currentIndex = slideIndex;
    }
}