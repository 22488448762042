export function linearEquation (x1, x2, y1, y2) {

    return function (t) {
        let valX1 = typeof x1 === 'function' ? x1() : x1,
            valY1 = typeof y1 === 'function' ? y1() : y1,
            valX2 = typeof x2 === 'function' ? x2() : x2,
            valY2 = typeof y2 === 'function' ? y2() : y2;

        let coeff = (valY2 - valY1) / (valX2 - valX1),
            a = valY1 - coeff * valX1;
        return coeff * t + a;
    };
}
