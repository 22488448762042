import router from "../Router";
import {AComponent} from "../abstract/AComponent";

export class Clients extends AComponent {
	initialize(params) {

		this.$$clients = '.client';
        this.events = {
            "click .client" : 'openClient',
            "click .close" : 'closeClient'
        };
        this.$currentClient = null;

		super.initialize(params);

		this.isShown = false;
	}

    openClient(e) {
		e.preventDefault();
		this.$currentClient = e.currentTarget;
        this.$$clients.forEach($client => {
			$client.classList.remove('open');
            $client.style.height = '147px';
        });

        let $currentDetail = this.$currentClient.querySelector('.details');
        let $currentDetailContent = this.$currentClient.querySelector('.details-content');


        let height = 0;
        $currentDetail.style.height = height+'px';
        this.$currentClient.classList.add('open');
        window.scrollTo(0,(F._.scrollTop - 80) + this.$currentClient.getBoundingClientRect().top);


        setTimeout(() => {
            height+= $currentDetailContent.getBoundingClientRect().height;
            $currentDetail.style.height = height+'px';
            this.$currentClient.style.height = (height + 187)+'px';
        }, 10);
	}

	closeClient(e) {
        e.preventDefault();
        setTimeout(() => {
            let $currentDetail = this.$currentClient.querySelector('.details');
            $currentDetail.style.height = '0px';
            this.$currentClient.style.height = '147px';
        }, 10);

        setTimeout(() => {
            this.$currentClient.classList.remove('open');
        }, 500);
	}
}