import { ASlider } from "../abstract/ASlider";

export class ClientsSlider extends ASlider {
	initialize(params) {
		this.$$items = '.client';
		this.arrowsNavigation = true;

		super.initialize(params);
	}
	each (cb) {
		let index = 0;
		for(let i = 0, $item = null; $item = this.$$items[i]; i++) {
			if(Array.isArray(this.$$items[i])) {
				let e = this.$$items[i].length;
				while(e--) {
					cb(this.$$items[i][e], index);
					index++;
				}
			} else {
				cb(this.$$items[i], index);
				index++;
			}
		}
	}
	resize(wW, wH) {
		super.resize(wW, wH);


		let maxSize = 0;
		this.$$items.forEach($$items => {
			let width = 0;
			$$items.forEach($item => {
				$item.style.left = width+'px';
				width+= $item.getBoundingClientRect().width + parseInt(window.getComputedStyle($item)['margin-right']);
			});
			maxSize = Math.max(maxSize, width);
		});

		this.$el.style.width = maxSize+'px';

	}
	render () {
		let numberBySlide = 4;

		if(F.isMobile()) {
			numberBySlide = 2;
		}

		let $items = this.$el.querySelectorAll(this.$$items);
		let index = 0;
		let countBySlide = 0;


		this.$$items = [];
		for(let i = 0, $item = null; $item = $items[i]; i++) {
			if(!this.$$items[index]) this.$$items[index] = [];

			this.$$items[index].push($item);

			countBySlide++;
			if(countBySlide === numberBySlide) {
				countBySlide = 0;
				index++;
			}
		}

		super.render();
	}
}