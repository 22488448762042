/**
 * http://krasimirtsonev.com/blog/article/A-modern-JavaScript-router-in-100-lines-history-api-pushState-hash-url
 */
import {AEventDispatcher} from "./abstract/AEventDispatcher";

class Router extends AEventDispatcher {
    constructor () {
        super();

        this.base = F.CONFIG.PATH_URL;
        this.currentUrl = null;
        this.routes = null;
    }
    start () {
        this.onChangeUrl = this.onChangeUrl.bind(this);
        /**
         * le setTimeout permet d'attendre que le scroll change si le mec fait back avant de lancer la transition
         */
        window.addEventListener('popstate', () => { setTimeout(this.onChangeUrl.bind(this), 20)}, false);


        this.onChangeUrl();
    }
    navigate (path, options = {}) {
        path = (path||'').replace(F.CONFIG.ROOT_URL, '');
        let url = this.base+path;

        if(url !== this.currentUrl) {
            window.history.pushState({}, path, url);
            if(!options.silent) {
                this.onChangeUrl();
            }
        }
    }
    urlMatch(url, pattern) {
        if((url === F.CONFIG.ROOT_URL || url === F.CONFIG.PATH_URL) && pattern === F.CONFIG.PATH_URL ) {
            return true;
        }

        let reg = pattern.split('/*').join('\\/(.*)')+'\\/?';

        let match = url.match(reg);

        if(match && match.length > 1) {
            let arg = Array.prototype.slice.call(match);
            arg.shift();

            return arg;
        } else {
            return false;
        }
    }
    onChangeUrl() {
        let url =  window.location.pathname;
        if(this.currentUrl !== url) {
            this.currentUrl = url;
            let name = null;
            let match = null;

            if(this.routes) {
                for(let path in this.routes) {
                    match = this.urlMatch(url, path);
                    if(match) {
                        name = this.routes[path];

                        if(!Array.isArray(match)) {
                            match = null;
                        }
                        break;
                    }
                }
            }

            this.trigger.apply(this, ['GO_TO', this.currentUrl, name].concat(match || []));
            if(name) {
                this.trigger.apply(this, ['GO_TO:'+name, this.currentUrl].concat(match || []));
            }
            //window.ga && ga('send', 'pageview');
        }
    }
}

export default new Router();