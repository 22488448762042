import { ATogglableComponent } from "../abstract/ATogglableComponent";

export class SelectFilterItem extends ATogglableComponent {
	initialize(params) {

		this.closeOnOutsideClick = true;
		this.type = '';
		this.$choices = '.filter-item-choices';
		this.values = [];
		this.$$checkboxs = 'input';
		this.name = '';
		this.$checkboxAll = 'input[name="all"]';
		this.$toggle = '.filter-item-toggle';
		this.$toggleLabel = '.filter-item-toggle-label';
		this.rewrite = false;
		this.multiple = true;
		this.$counter = '.counter';
		this.$select = 'select';
        this.$$a = 'a';
		this.events = {
			'click .filter-item-toggle' : 'onClickToggle',
			'click .overlay-filter-item' : 'onClickOverlay',
			'click .filter-item-choices .btn' : 'onClickApply',
			'change input[type="checkbox"]' : 'onChangeInput',
            'click a' : 'onClick',
		};

		super.initialize(params);
	}
	onClickToggle(e) {
		e.preventDefault(); e.stopPropagation();
		this.toggle();
	}
	render() {
		super.render();

		this.values = this.getValues();
	}
	onChangeInput(e) {
		if(!this.multiple && e.currentTarget.checked) {
			this.$$checkboxs.forEach($check => {
				if($check.name !== e.currentTarget.name) {
					$check.checked = false;
				}
			});
		} else if(this.$checkboxAll.checked && e.currentTarget.name !== 'all' && e.currentTarget.checked) {
			this.$checkboxAll.checked = false;
		} else if(e.currentTarget.name === 'all' && e.currentTarget.checked) {
			this.$$checkboxs.forEach($check => {
				if($check.name !== 'all') {
					$check.checked = false;
				}
			});
		}
	}

    onClick(e) {
        if(e && e.preventDefault) e.preventDefault();

        this.$$a.forEach($a => {
            if($a === e.currentTarget) {
                $a.parentNode.classList.add('current');
                $a.setAttribute('tabindex', -1);
            } else {
                $a.parentNode.classList.remove('current');
                $a.removeAttribute('tabindex');
            }
        });

        this.values = [e.currentTarget.getAttribute('data-value')];

        this.trigger('apply');
        this.setLabel();
        this.hide();
    }

	getValues() {
		let values = [];
		this.$$checkboxs.forEach($check => {
			if($check.checked) {
				values.push($check.name);
			}
		});

		return values;
	}
	setValues(values) {
		this.$$checkboxs.forEach($check => {
			$check.checked = values.indexOf($check.name) !== -1;
		});
	}
	onClickOverlay(e) {
		this.hide();
		this.cancel();
	}
	cancel() {
		this.setValues(this.values);
		this.hide();
	}
	willAppear() {
		super.willAppear();

		this.$toggleLabel.style.width = (this.$toggleLabel.getBoundingClientRect().width + 10) + 'px';

		if(this.values.indexOf('all') === -1) {
			this.setLabel();
		}
	}
	getLabelForValue(value) {
		let option = this.$select.querySelector('[value="' + value + '"]');
		return option ? option.innerText : '';
	}
    unselectValue(value) {
        let option = this.$el.querySelector('[name="'+value+'"]');
        option.checked = false;
        this.onChangeInput({currentTarget : option});
        this.apply();
	}
	apply() {
		this.values = this.getValues();
		if(!this.values.length) {
			this.values = ['all'];
			this.setValues(this.values);
		}
		if(this.values.indexOf('all') === -1) {
			this.setLabel();
		} else {
			this.resetLabel();
		}
		this.trigger('apply', this);
		this.hide();
	}
	setLabel() {
		this.$el.classList.add('has-value');
		this.$toggleLabel.innerHTML = (this.values[0] != null) ? this.getLabelForValue(this.values[0]) : this.$toggle.getAttribute('data-label-all');

	}
	resetLabel() {
		this.$toggleLabel.innerHTML = this.$toggle.getAttribute('data-label-all');
		this.$el.classList.remove('has-value');
	}
	onClickApply(e) {
		e.preventDefault(); e.stopPropagation();
		this.apply();
	}
	show() {
        super.show();
        //this.$choices.style.maxWidth = Math.min(350, (F._.wW - this.$el.getBoundingClientRect().left - 20)) + 'px';
    }
}