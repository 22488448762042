export class SwiperLogos extends HTMLElement {
    constructor() {
        super();
        this.SCREEN_MAX = 3840;
    }

    buildMarquee() {
        const clone = this.innerHTML;
        const firstElement = this.children[0];
        const screenW = Math.max(window.innerWidth, this.SCREEN_MAX);
        const nbClones = Math.ceil(screenW / firstElement.offsetWidth);
        const speed = this.dataset.speed ? parseInt(this.dataset.speed, 10) : 0.2;
        let i = 0;

        for (let a = 0; a < nbClones; a++) {
            this.insertAdjacentHTML('beforeend', clone);
        }

        setInterval(function () {
            firstElement.style.marginLeft = `-${i}px`;
            if (i > firstElement.clientWidth) {
                i = 0;
            }
            i = i + speed;
        }, 0);
    }

    init() {
        this.style.display = 'flex';
        this.buildMarquee();
    }

    connectedCallback() {
        setTimeout(() => {
            this.init();
        })
    }
}