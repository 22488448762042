export class Render {
    constructor () {
        this.update = this.update.bind(this);

        this.datas = [];
        // this.stats = new Stats();
        // this.stats.dom.id = "stats";
        // this.stats.showPanel(0);
        // document.body.appendChild( this.stats.dom );
        this.update();
    }
    update()
    {
        // this.stats.begin();
        let i =  this.datas.length;
        while (i --) {
            this.datas[i]();
        }
        // this.stats.end();
    }

    add(cb)
    {
        if (typeof cb !== "function") { return false; }
        this.datas.push(cb);
    }

    remove(cb)
    {
        let i = this.datas.length - 1;
        while (i >= 0) {
            if( this.datas[i] === cb ) {
                this.datas.splice(i, 1);
                break;
            }
            i--;
        }
    }
}