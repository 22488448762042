import { AScrollableElmt } from '../abstract/AScrollableElmt.js';

export class VideoHome extends AScrollableElmt {
    initialize(params) {
        this.isPlaying = false;
        this.$video = ".video";

        super.initialize(params);
    }

    render() {
        super.render();
    }

    didDisappearInScroll () {
        this.$video.pause()
    }

    scroll(scrollTop, lastScrollTop) {
        if ((this.bounds.bottom < scrollTop + this.wH) && !this.isPlaying ) {
            this.$video.play();
            this.isPlaying = true;
        }

        super.scroll(scrollTop, lastScrollTop);
    }
}