import Swiper from 'swiper';
import 'swiper/css';

const swipersConf = {
    'single.post': {
        slidesPerView: 1.5,
        spaceBetween: 26,
        breakpoints: {
            510: {
                slidesPerView: 2.2
            },
            767: {
                slidesPerView: 2.6
            },
            959: {
                slidesPerView: 3,
                spaceBetween: 40
            },
            1024: {
                slidesPerView: 3,
                spaceBetween: 50
            },
            1232: {
                slidesPerView: 3,
                spaceBetween: 50
            }
        }
    },
    'actus.blog': {
        slidesPerView: 1.5,
        spaceBetween: 26,
        breakpoints: {
            510: {
                slidesPerView: 2.2
            },
            767: {
                slidesPerView: 2.6
            },
            959: {
                slidesPerView: 3,
                spaceBetween: 40
            },
            1024: {
                slidesPerView: 3,
                spaceBetween: 50
            },
            1232: {
                slidesPerView: 3,
                spaceBetween: 50
            }
        }
    }
}

export class ASwiper extends HTMLElement {
    constructor(swiperConf) {
        super();
        this.elClass = '.swiper-js-container';
        this.swiperConf = swiperConf;
    }

    init() {
        const swiperContainerEl = this.querySelector(this.elClass);
        const swiperConfKey = this.dataset.confKey;

        if (swiperConfKey) this.swiperConf = swipersConf[swiperConfKey];
        if (swiperContainerEl && this.swiperConf) {
            // Change nextEl/prevEl to HTMLElement inside swiper instance
            if (this.swiperConf && this.swiperConf.navigation) {
                this.swiperConf.navigation.nextEl = this.querySelector(this.swiperConf.navigation.nextEl);
                this.swiperConf.navigation.prevEl = this.querySelector(this.swiperConf.navigation.prevEl);
            }
            this.swiper = new Swiper(swiperContainerEl, { ...this.swiperConf })

            return;
        }

        console.warn(this, ' ::: ', 'Swiper configuration no found or swiper container .swiper-js-container not found');
    }

    connectedCallback() {
        setTimeout(() => {
            this.init()
        }, 250)
    }
}