import router from "../Router";
import {AComponent} from "../abstract/AComponent";

export class ShowMore extends AComponent {
	initialize(params) {
        this.$$hide = '.hide';
        this.$showMoreContainer = '.showMore-container';

        this.events = {
            "click .showMore" : 'toggleShow'
        };

		super.initialize(params);

	}

	toggleShow(e) {
		e.preventDefault();
		if(this.$showMoreContainer.classList.contains('open')) this.showAll();
		else this.hideAll();

	}

    hideAll() {
        this.$showMoreContainer.classList.add('open');
        this.$showMoreContainer.classList.remove('close');
        let numActu = 0;
        this.$$hide.forEach($actu => {
            $actu.classList.add('hide');
        });
        let elmnt = document.getElementById(this.$showMoreContainer.getAttribute('data-id'));
        elmnt.scrollIntoView();
    }

	showAll() {
        this.$showMoreContainer.classList.add('close');
        this.$showMoreContainer.classList.remove('open');
		this.$$hide.forEach($actu => {
			$actu.classList.remove('hide');
        });
	}
}