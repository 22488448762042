import router from "../Router";
import {AComponent} from "../abstract/AComponent";

export class Header extends AComponent {
	initialize(params) {
        this.$menuMobile = '.mobile-navigation-wrapper';
        this.$burger = '.burger';
        this.menuShown = false;


        this.events = {
            "click .burger" : 'toggle',
            "click .mobile-navigation-wrapper a" : 'toggle',
            ///"click a[href='#']" : 'doNothing'
        };
		super.initialize(params);

		this.isShown = false;
	}
	scroll(scrollTop, lastScrollTop) {
		if(scrollTop >= 250 && !this.isShown) {
			this.show();
		} else if(scrollTop < 250 && this.isShown) {
			this.hide();
		}
	}
	doNothing(e){
		e.preventDefault();
	}
	resize() {}
	bind() {
		super.bind();
		this.onRouterGoTo = this.onRouterGoTo.bind(this);
		router.on('GO_TO', this.onRouterGoTo);
	}
	unbind() {
		super.unbind();
		router.off('GO_TO', this.onRouterGoTo);
	}
	onRouterGoTo(url) {
		this.hide();
	}
	show() {
		if(this.isShown) return;

		if(!this.isLoaded) {
			this.willAppearInScroll();
		}

		this.isShown = true;
		this.$el.classList.add('fixe');
	}
	hide() {
		if(!this.isShown) return;
		this.isShown = false;
		this.$el.classList.remove('fixe');
	}

    toggle(e) {
		if(e.currentTarget.getAttribute('href') != '#') {
            if(!this.menuShown) {
                this.menuShown = true;
                this.$menuMobile.classList.add('show');
                this.$burger.classList.add('menuopen');
            } else {
                this.menuShown = false;
                this.$menuMobile.classList.remove('show');
                this.$burger.classList.remove('menuopen');
            }
		} else {
            e.preventDefault();
		}
    }
}