import { ASlider } from "../abstract/ASlider";

export class VerbatimsSlider extends ASlider {
	initialize(params) {
		this.$$items = '.temoignage';
		this.bullets = true;

		super.initialize(params);
	}
	each (cb) {
		let index = 0;
		for(let i = 0, $item = null; $item = this.$$items[i]; i++) {
			if(Array.isArray(this.$$items[i])) {
				let e = this.$$items[i].length;
				while(e--) {
					cb(this.$$items[i][e], index);
					index++;
				}
			} else {
				cb(this.$$items[i], index);
				index++;
			}
		}
	}
	resize(wW, wH) {
		super.resize(wW, wH);


		let maxSize = 0;
		this.$$items.forEach($$items => {
			let height = 0;
			$$items.forEach($item => {
				$item.style.top = height+'px';
				height+= $item.getBoundingClientRect().height + parseInt(window.getComputedStyle($item)['margin-bottom']);
			});
			maxSize = Math.max(maxSize, height);
		});

		this.$el.style.height = maxSize+'px';

	}
	render () {
		let numberBySlide = 1;


		let $items = this.$el.querySelectorAll(this.$$items);
		let index = 0;
		let countBySlide = 0;


		this.$$items = [];
		for(let i = 0, $item = null; $item = $items[i]; i++) {
			if(!this.$$items[index]) this.$$items[index] = [];

			this.$$items[index].push($item);

			countBySlide++;
			if(countBySlide === numberBySlide) {
				countBySlide = 0;
				index++;
			}
		}

		super.render();
	}
}