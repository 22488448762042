export class VideoPlayerCW extends HTMLElement {
    constructor() {
        super();
        this.btnPlayEl = null;
        this.isPlaying = false;
        this.plyrPlayer = null;
    }

    setPlaying(state) {
        this.isPlaying = state;
        this.classList.toggle('playing', state);
        state && this.plyrPlayer.play();
    }

    init() {
        this.style.display = 'block';
        this.plyrPlayer = new window.Plyr(this.querySelector('.plyr__video-embed'));
        if (this.plyrPlayer) {
            this.btnPlayEl = this.querySelector('.video-poster-wrapper');
            if (this.btnPlayEl) {
                this.btnPlayEl.addEventListener('click', () => {
                    this.setPlaying(true);
                })
            }

            this.plyrPlayer.on('playing', () => this.setPlaying(true));
            this.plyrPlayer.on('ended', () => this.setPlaying(false));
            this.plyrPlayer.on('pause', () => this.setPlaying(false));
        }
    }

    connectedCallback() {
        setTimeout(() => {
            this.init();
        })
    }
}