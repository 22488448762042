/**
 * @todo
 *  DONE faire un truc comme $refs dans vue.js pour récuperer l'instance
 *  DONE - automatiser le passage des options
 *  - pouvoir récuperer les datas du parent simplement (comme props dans vue.js en gros)
 *  - finir le progress
 *  - est ce qu'on passerait meme pas ca dans le ALoadableView... LOL
 */
import {ALoadableView} from "./ALoadableView";

export class AComponent extends ALoadableView {

    initialize(params) {
        this.$$components = '[data-component]';
        this.subComponents = null;
        this._loadingQueue  = null; //@todo : trouver un meilleur nom
        this.firstAppearance  = true;
        this.$refs  = null;

        super.initialize(params);
    }
    render () {
        super.render();

        this.subComponents = [];
        this._loadingQueue = [];
        this.$refs = {};

        this.componize();

        if (this.isLoaded) {
            this.isLoaded = this._loadingQueue.length === 0;
        }

        return this;
    }
    componize() {
        for (let i = 0, $component = null; $component = this.$$components[i]; i++) {
            let name = $component.getAttribute('data-component'),
                options = $component.getAttribute('data-component-options') || '{}',
                condition = $component.getAttribute('data-component-if') || null,
                ref = $component.getAttribute('data-ref') || null;

            if (!$component.getAttribute('data-component_instantiated')) {
                options = JSON.parse(options);
                options.el = $component;

                if (condition) {
                    if (!eval(condition)) continue; //@todo : c'est quand meme pas ouf ca, niveau secu et tout
                    // continue;
                }

                console.info('Loading component "' + name + '"');

                if (!F.components[name]) {
                    // throw 'component "' + name + '" needed during render but not loaded, see import.js file';
                    console.error('Component "' + name + '" needed during render but not loaded, see import.js file');
                    continue;
                }
                let sub = new F.components[name](options);
                sub.render();
                $component.setAttribute('data-component_instantiated', true);
                if (ref) {
                    if (this.$refs[ref] && !Array.isArray(this.$refs[ref])) {
                        this.$refs[ref] = [this.$refs[ref]];
                    }

                    if (this.$refs[ref]) {
                        this.$refs[ref].push(sub)
                    } else {
                        this.$refs[ref] = sub;
                    }
                }
                if (!sub.isLoaded) {
                    this._loadingQueue.push(sub);
                }
                this.subComponents.push(sub);
            }
        }

    }
    eachSubComponents (cb) {
        let i = this.subComponents.length;
        while (i--) {
            cb.call(this, this.subComponents[i], i);
        }
    }
    bind () {
        super.bind();
        this.eachSubComponents(function (component) {
            component.bind();
        });
    }
    unbind () {
        super.unbind();

        this.eachSubComponents(function (component) {
            component.unbind();
        });
    }
    scroll (scrollTop, lastScrollTop) {
        this.eachSubComponents(function (component) {
            component.scroll && component.scroll(scrollTop, lastScrollTop);
        });
    }
    load () {
        this.loadSubComponents();
    }
    loadSubComponents () {
        if (this._loadingQueue.length) {
            this.loadSubComponent(0);
        } else {
            this.ready();
        }
    }
    loadSubComponent (index) {
        let sub = this._loadingQueue[index];
        this.onSubComponentProgress = this.onSubComponentProgress.bind(this);
        this.onSubComponentReady = this.onSubComponentReady.bind(this);

        sub.on('progress', this.onSubComponentProgress);
        sub.on('ready', this.onSubComponentReady);

        sub.load();
    }
    onSubComponentProgress (event, component) {

    }
    onSubComponentReady (component) {
        component.off('progress', this.onChildProgress);
        component.off('ready', this.onChildReady);
        //this.startPercen+= Math.max(0, Math.min(child.percenPounds || 100));

        let currentIndex = -2;
        for (let i = 0, loadableChild = null; loadableChild = this._loadingQueue[i]; i++) {
            if (loadableChild === component) {
                currentIndex = i;
                break;
            }
        }

        if (this._loadingQueue[currentIndex + 1]) {
            this.loadSubComponent(currentIndex + 1);
        } else {
            this.ready()
        }
    }
    willAppear () {
        this.eachSubComponents(function (component) {
            component.willAppear();
        });

        if (this.firstAppearance === true) {
            this.willAppearForFirstTime();
        }
    }
    willDisappear () {
        this.eachSubComponents(function (component) {
            component.willDisappear();
        });
    }
    willAppearForFirstTime () {
        this.eachSubComponents(function (component) {
            component.willAppearForFirstTime();
        });
    }
    didAppearForFirstTime () {
        this.eachSubComponents(function (component) {
            component.didAppearForFirstTime();
        });

    }
    didAppear () {
        this.eachSubComponents(function (component) {
            component.didAppear();
        });

        if (this.firstAppearance === true) {
            this.didAppearForFirstTime();
            this.firstAppearance = false;
        }
    }
    didDisappear () {
        this.eachSubComponents(function (component) {
            component.didDisappear();
        });
    }
}