export class Cookies {
    static get(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return false;
    }
    static set(cname, cvalue, exdays) {
        let d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }
    static loadTracking() {
        /*
        let script = document.createElement('script');
        script.src = '//cdn.tradelab.fr/tag/44ad48e92b.js';
        script.async = '1';
        document.body.appendChild(script);
        */

    }
    static acceptCookie() {
        Cookies.set('accept-cookies', 'yes', 30 * 12);

        window['ga-disable-'+F.CONFIG.GA_UA] = false;
        //window.ga && window.ga('send', 'pageview');

        Cookies.loadTracking();
    }
    static declineCookie() {
        window['ga-disable-'+F.CONFIG.GA_UA] = true;
        Cookies.set('accept-cookies', 'no', 2);
    }
}