import { ASwiper } from '../abstract/ASwiper.js'

export class SwiperTemoignages extends ASwiper {
    constructor() {
        super({
            slidesPerView: 1.4,
            spaceBetween: 40,
            slidesOffsetBefore: 24,
            slidesOffsetAfter: 24,
            breakpoints: {
                510: {
                    slidesPerView: 1.6,
                },
                767: {
                    slidesPerView: 1.8
                },
                960: {
                    slidesPerView: 2
                },
                1024: {
                    slidesPerView: 'auto',
                    spaceBetween: 40,
                    slidesOffsetBefore: 30,
                    slidesOffsetAfter: 30,
                },
                1275: {
                    slidesPerView: 'auto',
                    spaceBetween: 63
                }
            }
        });
    }
}