import { Parallax } from "./Parallax";
import { setTransform } from "../utils/setTransform";

export class HeaderHomeVisual extends Parallax {
  initialize(params) {

    this.end_position = 1;

    this.$$imgs = '.cartes, .telephone';

    this.$$cartes = '.visuel-carte';

    super.initialize(params)
  }
  scrollInViewport(scrollTop, lastScrollTop) {

    let anim = this.equation(scrollTop);


    this.$$imgs.forEach($img => {
      setTransform($img, 'translate' + this.direction + '(' + (anim * 350) + 'px)');
    });

    this.$$imgs[1].style.opacity = Math.min(1, 2 - anim * 2.1);

    this.$$cartes.forEach(($carte, index) => {
      let i = 3 - index;
      setTransform($carte, 'translate(' + (anim * 60 * i) + 'px, ' + (anim * -60 * i) + 'px)');
    });

  }
}