import {AView} from "./AView";


export class ALoadableView extends AView {
    initialize(params) {
        this.isLoaded = true;
        this.isLoading = false;

        super.initialize(params);
    }
    load() {
        this.isLoading = true;
    }
    error(event) {
        console.warn(event);
    }
    progress(event) {
        this.trigger('progress', (event.progress * 100) >> 0, this);
    }
    ready(event) {
        if(this.isLoaded) return;
        this.isLoaded = true;
        this.isLoading = false;
        this.trigger('ready', this);
    }
}