import {AComponent} from "../abstract/AComponent";
import {Cookies} from "../utils/Cookies";

export class PopinCookie extends AComponent {
    initialize(params) {

        this.events = {
            'click .decline' : 'onClickDecline',
            'click .accept' : 'onClickAccept'
        };

        super.initialize(params);
    }
    onClickDecline (e) {
        e.preventDefault(); e.stopPropagation();
        Cookies.declineCookie();
        F.Popin.hide();
    }
    onClickAccept (e) {
        e.preventDefault(); e.stopPropagation();
        Cookies.acceptCookie();
        F.Popin.hide();
    }
}