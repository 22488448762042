import { AComponent } from "../abstract/AComponent";
import { isInside } from "../utils/isInside";

export class ATogglableComponent extends AComponent {
    initialize(params) {
        super.initialize(params);

        this.handleKeyboard = this.handleKeyboard || false;
        this.className = 'show';
        this.overlayName = this.overlayName || null;

        this.closeOnOutsideClick = this.closeOnOutsideClick === undefined ? (this.overlayName && this.overlayName.length > 0) : this.closeOnOutsideClick;
        this.isOpen = false;
        this.lockScroll = false;
    }

    toggle() {
        this[this.isOpen ? "hide" : "show"]();
    }

    bind() {
        super.bind();

        this.onKeyDown = this.onKeyDown.bind(this);
        this.onClickBody = this.onClickBody.bind(this);
    }

    unbind() {
        super.unbind();

        document.body.removeEventListener('click', this.onClickBody);
    }
    onClickBody(e) {
        if(e.target !== this.$el && !isInside(e.target, this.$el)) {
            this.hide();
        }
    }
    show() {
        if (this.isOpen) return;
        this.isOpen = true;

        let _show = () => {
            if(!this.isOpen) return;
            this.$el.classList.add(this.className);

            this.trigger('show', this);
            if(this.overlayName) {
                document.body.classList.add(`overlay-${this.overlayName}-open`);
            }

            if(this.closeOnOutsideClick) {
                document.body.addEventListener('click', this.onClickBody)
            }

            if(this.lockScroll) {
                F.lockScroll();
            }
            if(this.handleKeyboard) {
                document.addEventListener('keydown', this.onKeyDown);
            }
        };

        if(this.isLazy && !this.isAwake) {
            this.wakeup(_show);
        } else {
            _show();
        }
    }
    onKeyDown(e) {
        if(e.keyCode === 27) {
            this.hide();
        }
    }
    hide() {
        if (!this.isOpen) return;
        this.$el.classList.remove(this.className);
        this.isOpen = false;

        this.trigger('hide', this);
        if(this.overlayName) {
            document.body.classList.remove(`overlay-${this.overlayName}-open`);
        }
        if(this.closeOnOutsideClick) {
            document.body.removeEventListener('click', this.onClickBody)
        }

        if(this.lockScroll) {
            F.unlockScroll();
        }
        if(this.handleKeyboard) {
            document.removeEventListener('keydown', this.onKeyDown);
        }
    }
}