import Swiper from 'swiper';
import {EffectFade, Pagination} from 'swiper/modules';
import {AComponent} from "../abstract/AComponent";

export class BlocCarousel extends AComponent {
	initialize(params) {
        this.container = '.carousel-wrapper';
        this.$$hide = '.hide';
        this.$showMoreContainer = '.showMore-container';

        this.events = {
            "click .slide" : 'showSlide',
        };

        this.carousel = new Swiper('.carousel-wrapper .illustration', {
            effect: "fade",
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
                renderBullet: function (index, className) {
                    return '<span class="' + className + '" data-item="' + index + '"></span>';
                },
            },
            on: {
                init:  (swiper) => {
                    this.activeSlide (swiper.activeIndex);
                },

                slideChange: (swiper) => {
                    this.activeSlide (swiper.activeIndex);
                }
            },
            modules: [EffectFade, Pagination]
        });

		super.initialize(params);

	}

	showSlide(e) {
		e.preventDefault();
        let $target = e.currentTarget;
        const dataIndex = $target.dataset.item;
        this.carousel.slideTo(dataIndex);

	}

    activeSlide (slideIndex) {
        let activeColor = '';
        const $illustrations = document.querySelectorAll(this.container + ' .illustration-item');
        const $pages = document.querySelectorAll(this.container + ' .pagination .swiper-pagination-bullet');
        const $slides = document.querySelectorAll(this.container + ' .slide');
        // const pageElemActive = document.querySelector(this.container + ' .pagination [data-item="'+ slideIndex +'"]');

        // console.log(pageElemActive);

        if ( typeof (slideIndex) !== "undefined" ) {
            $illustrations.forEach ((elem) => {
                if ( elem.dataset.item ==  slideIndex) {
                    //elem.classList.add ('active');
                    activeColor = elem.style.backgroundColor;
                }
            });

            $pages.forEach ((elem) => {
                elem.style.backgroundColor = activeColor;
            });

            $slides.forEach ((elem) => {
                if ( elem.dataset.item ==  slideIndex) {
                    elem.classList.add ('active');
                } else {
                    elem.classList.remove ('active');
                }
            });
        }
    }
}