import {AEventDispatcher} from "./AEventDispatcher";
import {delegateEventListener, undelegateEventListener} from "../../vendor/event-delegation";

export class AView extends AEventDispatcher {
    constructor(params) {
        super();
        this.events = null;

        this.initialize(params);
    }
    initialize (params) {
        for (let key in params) {
            if (params.hasOwnProperty(key) && ['el', '$el'].indexOf(key) === -1) {
                this[key] = params[key];
            }
        }

        this.setElement(params.$el || params.el || null)
    }
    bindEvents(events) {
        for(let key in events) {
            if(events.hasOwnProperty(key)) {
                this.bindEvent(key, events[key]);
            }
        }
    }
    bindEvent(str, fn) {
        if(!this.events) {
            this.events = {};
        }

        if(typeof fn === 'string') {
            if(!this[fn] || typeof this[fn] !== 'function') {
                throw 'Method '+fn+' is registered on '+str+' but doesn\'t exist or is not a function';
            }
            fn = this[fn];
        }

        fn =  fn.bind(this);

        this.unbindEvent(str, fn);
        this.events[str] = fn;

        if(str.indexOf(' ') !== -1) {
            let parts = str.split(/ (.+)/);

            delegateEventListener(this.$el, parts[0], parts[1], fn);
        } else {
            this.$el.addEventListener(str, fn);
        }
    }
    unbindEvents(events) {
        for(let key in events) {
            if(events.hasOwnProperty(key)) {
                this.unbindEvent(key, events[key]);
            }
        }
    }
    unbindEvent(str, fn) {
        if(str.indexOf(' ') !== -1) {
            let parts = str.split(/ (.+)/);

            undelegateEventListener(this.$el, parts[0], parts[1], fn);
        } else {
            this.$el.removeEventListener(str, fn);
        }
    }
    setElement ($el) {
        this.$el = $el || null;

        if(typeof this.$el === 'string') {
            this.$el = document.querySelectorAll(this.$el)[0] || null;
        }

        if(!this.$el) {
            this.$el = document.createElement(this.tagName || 'div');
            if(this.className) {
                let classes = (typeof this.className === 'function' ? this.className() : this.className).split(' ');
                classes.forEach((className) => {
                    this.$el.classList.add(className);
                });
            }
        }
    }

    render() {

        this.performSelectors();

        return this;
    }

    bind() {
        this.resize = this.resize.bind(this);
        F.Resizable.add(this.resize);

        if(this.events) {
            this.bindEvents(this.events);
        }

        this.resize(F._.wW, F._.wH);
    }

    resize(wW, wH) {

    }

    unbind() {
        F.Resizable.remove(this.resize);

        if(this.events) {
            this.unbindEvents(this.events);
        }
    }

    performSelectors() {
        /**
         * define properties in your object like ;
         *  $h1 : 'h1',
         *  and you will have this.$h1 = this.$el.find('h1')
         */
        for (let key in this) {
            if (key && this.hasOwnProperty(key) && key !== '$el' && key !== '$' && key !== '$refs' && key[0] === '$' && this[key] && typeof this[key] === 'string') {
                if(key[1] === '$') {
                    this[key] = this.$el.querySelectorAll(this[key]);
                } else {
                    this[key] = this.$el.querySelector(this[key]);
                }
            }
        }
    }
}
