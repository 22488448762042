import {AComponent} from "../abstract/AComponent";

export class BlocOnglets extends AComponent {
	initialize(params) {
        this.container = '.onglets-main-wrapper';

        this.events = {
            "click .tab-item" : 'showSlide',
        };

		super.initialize(params);
        this.activeSlide(0);

	}

	showSlide(e) {
		e.preventDefault();
        let $target = e.currentTarget;
        const dataIndex = $target.dataset.tab;
        // console.log(this.$container);

        this.activeSlide (dataIndex);

	}

    activeSlide (slideIndex) {
        console.log ( this.container);
        const $illustrations = document.querySelectorAll(this.container + ' .image-item');
        const $tab = document.querySelectorAll(this.container + ' .tab-item');

        $illustrations.forEach ((elem) => {
            if ( elem.dataset.tab ==  slideIndex) {
                elem.classList.add ('active');
            } else {
                elem.classList.remove ('active');
            }
        });

        $tab.forEach ((elem) => {
            if ( elem.dataset.tab ==  slideIndex) {
                elem.classList.add ('active');
            } else {
                elem.classList.remove ('active');
            }
        });
    }
}