import {AComponent} from "../abstract/AComponent";
import {getScrollbarWidth} from "../utils/getScrollbarWidth";

const MARKUP = '<div class="popin-click-overlay full"></div><div class="popin-wrapper">' +
    '<div class="popin-scroll-wrapper">' +
    '<a href="#" class="popin-close">X</a>' +
    '<div class="popin-inner">' +
    '<div class="popin-content text"></div>' +
    '</div>' +
    '</div>' +
    '</div>';

export class Popin extends AComponent {
    initialize(params) {

        this.events = {
            'click .popin-close' : 'onClickClose',
            'click .popin-click-overlay' : 'onClickOverlay'
        };

        this.$title = '.popin-title';
        this.$content = '.popin-content';
        this.$close = '.popin-close';
        this.$wrapper = '.popin-wrapper';

        super.initialize(params);
    }
    onClickOverlay(e) {
        if(this.canClose) {
            e.stopPropagation();
            e.preventDefault();
            this.hide();
        }
    }
    onClickClose(e) {
        if(this.canClose) {
            e.stopPropagation();
            e.preventDefault();
            this.hide();
        }
    }
    render() {
        this.$el.innerHTML = MARKUP;
        super.render();
    }
    bind() {
        super.bind();
        this.onKeyDown = this.onKeyDown.bind(this);
    }
    show (title, content, canClose = true) {
        this.$wrapper.style.maxHeight = window.innerHeight + 'px';

        this.canClose = canClose;
        this.$content.innerHTML = content || '';
        //this.$title.innerHTML = title || '';

        if(this.canClose) {
            this.$close.style.display = 'block';
        } else {
            this.$close.style.display = 'none';
        }
        if(F.Header) F.Header.$el.style.paddingRight = getScrollbarWidth()+'px';
        document.body.style.paddingRight = getScrollbarWidth()+'px';
        document.body.style.overflow = 'hidden';
        document.getElementsByTagName('html')[0].style.overflow = 'hidden';

        this.$wrapper.scrollTop = 0;
        this.$$components = '[data-component]';
        this.performSelectors();
        this.componize();
        this.bind();
        this.isShown = true;
        this.$el.classList.add('show');

        document.addEventListener('keydown', this.onKeyDown);
    }
    onKeyDown(e) {
        if(e.keyCode === 27 && this.canClose) {
            this.hide();
        }
    }
    hide () {

        this.isShown = false;
        this.unbind();
        this.$el.classList.remove('show');
        document.removeEventListener('keydown', this.onKeyDown);
        setTimeout(() => {
            if(F.Header) F.Header.$el.style.paddingRight = '';
        document.body.style.paddingRight = '';
        document.body.style.overflow = '';
        document.getElementsByTagName('html')[0].style.overflow = '';

        this.subComponents = [];
        this.$content.innerHTML = '';
    }, 300);
    }
}