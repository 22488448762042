import {AScrollableElmt} from "../abstract/AScrollableElmt";

export class LazyImage extends AScrollableElmt {
    initialize(params) {
        this.deltaWillAppear = 300;
        super.initialize(params);

        this.$bkg = '[data-bkg-desktop]';
        this.$img = '[data-src-desktop]';
        this.src = null;
        this.isLoaded = false;
        this.isLoading = false;
    }
    render() {
        super.render();



        if(this.$bkg) {
            this.src = this.$bkg.getAttribute('data-bkg-desktop');

			if(F.isMobile() && this.$bkg.getAttribute('data-bkg-mobile')) {
				this.src = this.$bkg.getAttribute('data-bkg-mobile');
			}
        } else if(this.$el.getAttribute('data-bkg-desktop')) {
            this.$bkg = this.$el;
            this.src = this.$el.getAttribute('data-bkg-desktop');

			if(F.isMobile() && this.$el.getAttribute('data-bkg-mobile')) {
				this.src = this.$bkg.getAttribute('data-bkg-mobile');
			}
        }

        if(this.$img) {
            this.src = this.$img.getAttribute('data-src-desktop');
        } else if(this.$el.getAttribute('data-src-desktop')) {
			this.$img = this.$el;
			this.src = this.$el.getAttribute('data-src-desktop');
		}

    }
    willAppearInScroll() {
        super.willAppearInScroll();

        if(this.src && !this.isLoading && !this.isLoaded) {
            this.isLoading = true;
			window.requestIdleCallback(() => {
                this.image = new Image();
                this.image.src = this.src;

                this.ready = this.ready.bind(this);
                if(this.image.complete) {
                    this.ready();
                } else {
                    this.image.onload = this.ready;
                }
            });
        }
    }
    ready() {
		window.requestIdleCallback(this._ready.bind(this));
    }
    _ready() {
		this.isLoading = false;
		this.isLoaded = true;
		if (this.$bkg) {
			this.$bkg.style.backgroundImage = 'url(' + this.image.src + ')';
		}
		if (this.$img) {
			this.$img.setAttribute('src', this.image.src);
		}
		setTimeout(() => {
			this.$el.classList.add('loaded');
			this.$bkg && this.$bkg.classList.add('loaded');
		}, 50);
	}
}