export class Resizable {
    constructor () {
        this.datas = [];
        this.resize();
    }
    resize() {
        for(let i = 0; i < this.datas.length; i++) {
            this.datas[i].cb(F._.wW, F._.wH);
        }
    }
    add(cb) {
        if (typeof cb !== "function") { return false; }
        let data = { cb: cb };
        this.datas.push(data);
        data.id = this.datas.length;
    }
    remove(cb) {
        let i = this.datas.length - 1;
        while (i >= 0) {
            let data = this.datas[i];
            if( data.cb == cb ) {
                this.datas.splice(i, 1);
                break;
            }
            i--;
        }
    }
}