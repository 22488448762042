import {AComponent} from "./../abstract/AComponent";
import router from "../Router";

export class YoutubePlayer extends AComponent {
    initialize(params) {
        this.videoID = null;
        this.player = undefined;
        this.$placeholder = '.play-video';
        this.$video = '.video-player';
        this.$el = '.video-player-container';
        this.events = {
            'click .play-video' : 'onVideoPlay',
            'click .play-video a' : 'onClickLink'
        };

        super.initialize(params);
    }
    onClickLink (e) {
        e.preventDefault()
    }
    bind() {
        super.bind();

        this.onRouterGoTo = this.onRouterGoTo.bind(this);
        router.on('GO_TO', this.onRouterGoTo);

        this.onRouterGoTo(router.currentUrl);

    }
    unbind() {
        super.unbind();
        router.off('GO_TO', this.onRouterGoTo);
    }
    onRouterGoTo(url) {
        this.resetVideos();
    }
    onVideoPlay () {
        this.$el.classList.add('play-video');

        if(!window.YT || !this.player) {
            this.loadApi();

            window.onYouTubeIframeAPIReady = function() {
                this.setupPlayer();
            }.bind(this);
            if(window.YT) {
                this.setupPlayer();
            }
        } else {
            this.player.loadVideoById(this.videoID)
        }
    }

    loadApi() {
        let tag = document.createElement('script');
        let script = document.getElementsByTagName('script')[0];
        script.parentNode.insertBefore(tag, script);
        tag.src = "https://www.youtube.com/iframe_api";
    }

    setupPlayer() {
        this.player = new YT.Player(this.$video, {
            videoId: this.videoID,
            events: {
                'onReady': this.onVideoReady.bind(this),
                'onStateChange': this.onPlayerChange.bind(this)
            }
        });
    }

    onPlayerChange (e) {
        if (e.data === YT.PlayerState.ENDED) {
            this.resetVideos();
        }
    }

    onVideoReady (e) {
        e.target.playVideo();
    }

    playerPause() {
        if(this.player) {
            this.player.pauseVideo();
            if(F.isMobile()) {
                this.$el.classList.remove('play-video');
            }
        }
    }

    resetVideos () {
        this.$el.classList.remove('play-video');
        if(this.player){
            this.player.pauseVideo();
            this.player.seekTo(0);
        }
    }
}