import {AScrollableElmt} from "../abstract/AScrollableElmt";

export class LazyMultiImages extends AScrollableElmt {
    initialize(params) {
        this.deltaWillAppear = 300;
        super.initialize(params);
        this.$$imgs = '[data-src-desktop]';
        this.isLoaded = false;
        this.isLoading = false;
    }

    willAppearInScroll() {
        super.willAppearInScroll();

        if(!this.isLoaded) {
            this.$$imgs.forEach($img => {
                window.requestIdleCallback(() => {
                    let image = new Image();

                    function _ready() {
                        $img.setAttribute('src', image.src);
                        setTimeout(() => {
                            $img.classList.add('loaded');
                        }, 50);
                    }
                    let src = $img.getAttribute('data-src-desktop');

                    if(F.isMobile() && $img.getAttribute('data-src-mobile')) {
                        src = $img.getAttribute('data-src-mobile');
                    }
                    image.src = src;

                    if(image.complete) {
                        _ready();
                    } else {
                        image.onload = _ready;
                    }
                });
            });
        }

        this.isLoaded = true;
    }

    scroll(scrollTop, lastScrollTop) {
        super.scroll(scrollTop, lastScrollTop);
    }
    resize(wW, wH) {
        super.resize(wW,wH);
    }
}