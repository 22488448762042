
import {Popin} from "./classes/components/Popin";
import {PopinCookie} from "./classes/components/PopinCookie";
import {ClientsSlider} from "./classes/components/ClientsSlider";
import {VerbatimsSlider} from "./classes/components/VerbatimsSlider";
import {CartesSliderMobile} from "./classes/components/CartesSliderMobile";
import {Header} from "./classes/components/Header";
import {EventsSliderMobile} from "./classes/components/EventsSliderMobile";
import { AnimChiffre } from "./classes/components/AnimChiffre";
import { HeaderHomeVisual } from "./classes/components/HeaderHomeVisual";
import {LazyImage} from "./classes/components/LazyImage";
import {ShowMore} from "./classes/components/ShowMore";
import {YoutubePlayer} from "./classes/components/YoutubePlayer";
import {LangSwitch} from "./classes/components/LangSwitch";
import {LazyMultiImages} from "./classes/components/LazyMultiImages";
import {Clients} from "./classes/components/Clients";
import {SelectFilterItem} from "./classes/components/SelectFilterItem";
import {GrilleClients} from "./classes/components/GrilleClients";
import {VideoHome} from "./classes/components/VideoHome";
import {BlocCarousel} from "./classes/components/BlocCarousel.js";
import {BlocOnglets} from "./classes/components/BlocOnglets.js";

F.components = {
    Popin,
    PopinCookie,
    ClientsSlider,
    VerbatimsSlider,
    CartesSliderMobile,
    AnimChiffre,
    EventsSliderMobile,
    HeaderHomeVisual,
    Header,
    LazyImage,
    LazyMultiImages,
    ShowMore,
    YoutubePlayer,
    LangSwitch,
    Clients,
    SelectFilterItem,
    GrilleClients,
    VideoHome,
    BlocCarousel,
    BlocOnglets
};