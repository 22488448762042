import { AScrollableElmt } from "../abstract/AScrollableElmt";
import anime from 'animejs';

export class AnimChiffre extends AScrollableElmt {
  initialize(params) {
    this.number = 0;
    this.index = 0;

    this.__number = 0;

    this.$number = 'span';
    this.round = 10;

    this.deltaWillAppear = 0;

    super.initialize(params);
  }
  render() {
    super.render();

    this.$number.innerHTML = '0';

    //compte le nombre de chiffre après la virgule
    let roundAt = ((''+this.number).split('.')[1] || '').length;
    this.round = Math.pow(10, roundAt);
  }
  willAppearForFirstTimeInScroll() {
    anime({
      targets: this,
      __number: this.number,
      easing: 'linear',
      delay : (this.index + 1) * 100,
      duration: 600 + 200 * (this.index + 1),
      update: anim => {
        this.$number.innerHTML = Math.round(this.__number * this.round) / this.round;
      }
    });
  }
}