import {AComponent} from "./AComponent";
// import {swipe} from 'pure-swipe';

export class ASlider extends AComponent {
    initialize(params) {

        this.$$items = this.$$items || '.slider-item';
        this.autoResize = this.autoResize || false;
        this.currentIndex = -1;
        this.startIndex = 0;
        this.autoPlayTimer = null;
        this.autoPlayDuration = 0;
        this.arrowsNavigation = this.arrowsNavigation || false;
        this.bullets = this.bullets || false;
        this.loop = true;
        this.$$bullets = null;

        super.initialize(params);

        this.events = {
            'click .a-slider-arrow--next' : 'next',
            'click .a-slider-arrow--prev' : 'prev',
            'swiped-left' : 'onSwipedLeft',
            'swiped-right' : 'onSwipedRight'
        };

        if(this.$$bullets && typeof this.$$bullets === 'string') {
            this.events['click '+this.$$bullets] = 'onClickBullet';
        } else {
            this.events['click .a-slider-bullets a'] = 'onClickBullet';
        }
    }
    onSwipedLeft(e) {
        this.next()
    }
    onSwipedRight(e) {
        this.prev()
    }
    onClickBullet (e) {
        e.preventDefault();e.stopPropagation();
        this.goToSlide(parseInt(e.currentTarget.getAttribute('data-index')));
    }
    each (cb) {
        for(let i = 0, $item = null; $item = this.$$items[i]; i++) {
            cb(this.$$items[i], i);
        }
    }
    resize (wW, wH) {
        super.resize(wW, wH);

        if(this.autoResize) {
            /*if(!F.isMobile()) {
                let maxHeight = 0;
                this.each($item => {
                    maxHeight = Math.max(maxHeight, parseInt($item.offsetHeight));
                });

                this.$el.style.height = maxHeight + 'px';
            } else*/
            this.doAutoResize();
        }
    }
    doAutoResize () {
        if(this.autoResize && this.$$items[this.currentIndex]) {
            this.$el.style.height = parseInt(this.$$items[this.currentIndex].offsetHeight)+'px';
        }
    }
    render () {
        super.render();

        this.$el.classList.add('a-slider');
        if(this.autoResize) this.$el.classList.add('auto-resize');

        this.each($item => {
            $item.classList.add('a-slider-item');
        });

        this.renderNav();
    }
    renderNav () {
        if(this.$$items.length > 1) {
            if (this.arrowsNavigation) {

                [['next'/*, window.F.svgs.arrowbottomsimple */], ['prev'/*, window.F.svgs.arrowbottomsimple */]].forEach(arrow => {
                   let el = document.createElement('a');
                   el.className = 'a-slider-arrow a-slider-arrow--'+arrow[0];
                   //el.innerHTML = arrow[1];
                   this.$el.appendChild(el);
                });
            }

            if(this.bullets && !this.$$bullets) {
                let $$bulletsWrapper = document.createElement('div');
                $$bulletsWrapper.className = 'a-slider-bullets';

                let html = '';
                let i = 0;
                this.$$items.forEach($item => {
                    html+= '<a data-index="'+i+'" href="#" '+(i === this.currentIndex ? 'class="current"' : '')+'>.</a>';
                    i++;
                });

                $$bulletsWrapper.innerHTML = html;
                this.$el.appendChild($$bulletsWrapper);

                this.$$bullets = $$bulletsWrapper.childNodes;
            }
        }
    }
    findNextSlideIndex() {
        return this.$$items[this.currentIndex + 1] ? this.currentIndex + 1 : 0
    }
    findPrevSlideIndex() {
        return this.$$items[this.currentIndex - 1] ? this.currentIndex - 1 : this.$$items.length - 1;
    }
    next (e) {
        if(e) {
            e.preventDefault(); e.stopPropagation();
        }
        if(this.loop) {
            this.goToSlide(this.findNextSlideIndex());
        } else {
            if(this.$$items[this.currentIndex + 1]) {
                this.goToSlide(this.currentIndex + 1);
            } else {
                this.trigger('reached-end');
            }
        }
    }
    prev (e) {
        if(e) {
            e.preventDefault(); e.stopPropagation();
        }
        if(this.loop) {
            this.goToSlide(this.findPrevSlideIndex());
        } else {
            if(this.$$items[this.currentIndex - 1]) {
                this.goToSlide(this.currentIndex - 1);
            } else {
                this.trigger('reached-start');
            }
        }
    }
    goToSlide (index) {
        this.cancelAutoPlay();

        if(index === this.currentIndex) return false;

        if(this.$$items[this.currentIndex]) {
            if(Array.isArray(this.$$items[this.currentIndex])) {
                let i = this.$$items[this.currentIndex].length;
                while(i--) {
                    this.$$items[this.currentIndex][i].classList.remove('current');
                }
            } else {
                this.$$items[this.currentIndex].classList.remove('current');
            }
            if(this.$$bullets && this.$$bullets[this.currentIndex]) {
                this.$$bullets[this.currentIndex].classList.remove('current');
            }
        }

        if(this.$$items[index]) {

            if(Array.isArray(this.$$items[index])) {
                let i = this.$$items[index].length;
                while(i--) {
                    this.$$items[index][i].classList.add('current');
                }
            } else {
                this.$$items[index].classList.add('current');
            }

            if(this.$$bullets && this.$$bullets[index]) {
                this.$$bullets[index].classList.add('current');
            }
        }

        this.trigger('change', this.currentIndex, index, this.$$items[this.currentIndex] || null, this.$$items[index] || null);


        this.currentIndex = index;
        if(this.autoResize) {
            this.doAutoResize();
        }
    }
    cancelAutoPlay () {
        if(this.autoPlayTimer) {
            clearTimeout(this.autoPlayTimer);
            this.autoPlayTimer = null;
        }
    }
    startAutoPlay () {
        this.cancelAutoPlay();

        if(this.autoPlayDuration > 0) {
            this.autoPlayTimer = setTimeout(() => {
                this.next();
            }, this.autoPlayDuration * 1000);
        }
    }
    bind () {
        super.bind();
        if(!this.goToSlide(this.startIndex) && this.autoResize) {
            this.doAutoResize();
        }
        this.startAutoPlay();
    }
    unbind () {
        super.unbind();

        this.goToSlide(-1);

        this.cancelAutoPlay();
    }
}