import {AComponent} from "./AComponent";

export class AHorzScroller extends AComponent {
    initialize(params) {

        this.$scroller = this.$scroller || '.scroller';
        this.$list = this.$list || '.scroller-list';
        this.$$items = this.$$items || '.scroller-item';
        this.$barWrapper = this.$barWrapper || '.scroller-bar-wrapper';
        this.$bar = this.$bar || '.scroller-bar-wrapper > *';
        this.showArrow = this.showArrow !== undefined ? this.showArrow : true;
        this.$scrollerMask = this.$scrollerMask || '.scroller-mask';

        this.widthOne = 0;
        this.widthList = 0;
        this.events = {
            'click .a-horz-scroller-arrow-left' : 'onClickArrowLeft',
            'click .a-horz-scroller-arrow-right' : 'onClickArrowRight'
        };

        super.initialize(params);
    }
    render() {
        super.render();

        this.$el.classList.add('horz-scroller-wrapper');
        this.$barWrapper.classList.add('a-horz-scroller-bar-wrapper');
        this.$scroller.classList.add('a-horz-scroller');
        this.$list.classList.add('a-horz-scroller-list');

        if(this.showArrow) {
            ['Left', 'Right'].forEach(key => {
                this['$arrow' + key] = document.createElement('a');
                this['$arrow' + key].className = 'a-horz-scroller-arrow a-horz-scroller-arrow-' + key.toLowerCase();
                //this['$arrow' + key].innerHTML = F.svgs.rounded_arrow_left;
                this.$el.insertBefore(this['$arrow' + key], this.$barWrapper);
            });
        }
    }
    onScroll() {
        let left = this.$scroller.scrollLeft * this.widthBarWrapper / this.$scroller.scrollWidth;

        this.$bar.style.transform = 'translateX('+left+'px)';
        this.$bar.style.webkitTransform = 'translateX('+left+'px)';
    }
    resize(wW, wH) {
        super.resize(wW, wH);

        if(this.$scrollerMask) {
            this.$scrollerMask.style.height = (this.$scroller.offsetHeight - 20) + 'px';
        }
        this.widthWrapper = this.$scroller.offsetWidth;

        this.widthOne = 0;

        let width = 0;
        this.$$items.forEach(e => {
            width+= e.offsetWidth;
            let style = e.currentStyle || window.getComputedStyle(e);
            width+= parseFloat(style.marginLeft) + parseFloat(style.marginRight);

            if(!this.widthOne) {
                this.widthOne = e.offsetWidth + parseFloat(style.marginLeft);
            }
        });

        this.widthList = width;



        this.$list.style.width = this.widthList+'px';
        this.widthBarWrapper = this.$barWrapper.offsetWidth;
        this.widthBar = Math.max(50, this.widthWrapper * this.widthBarWrapper / this.$scroller.scrollWidth);

        this.$bar.style.width = this.widthBar+'px';

        this.$el.classList[this.widthList <= this.widthWrapper ? 'add' : 'remove']('a-horz-scroller--no-scroll');

        this.onScroll();
    }
    unbind() {
        super.unbind();
        this.$scroller.removeEventListener('scroll', this.onScroll);
    }
    onClickArrowLeft(e) {
        e.preventDefault(); e.stopPropagation();
        this.$scroller.scrollLeft = this.$scroller.scrollLeft - this.widthOne;
    }
    onClickArrowRight(e) {
        e.preventDefault(); e.stopPropagation();
        this.$scroller.scrollLeft = this.$scroller.scrollLeft + this.widthOne;
    }
    bind() {
        super.bind();
        this.onScroll = this.onScroll.bind(this);
        this.$scroller.addEventListener('scroll', this.onScroll, true);
    }

}